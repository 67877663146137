<template>
  <div>
    <div id="chat-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
      <!-- MOBILE VIEW CHAT -->
      <vs-sidebar
        v-model="isChatActive"
        position-right
        parent="body"
        default-index="1"
        class="add-new-data-sidebar items-no-padding half-vs-sidebar background-boi min-h-full min-w-full sm:hidden"
        spacer
      >
        <chat-side
          v-if="isChatActive"
          :click-not-close="false"
          :active-chat-user="activeChatUser"
          :is-chat-pinned="isChatPinned"
          :user-data="userData"
          :selected-contact="selectedContact"
          :typed-message="typedMessage"
          :settings="settingsTwo"
          @close="closeChat"
          @update-active-chat-user="updateActiveChatUser"
        ></chat-side>
      </vs-sidebar>

      <!-- MOBILE VIEW CHAT END -->
      <vs-sidebar
        id="chat-list-sidebar"
        v-model="isChatSidebarActive"
        class="items-no-padding flex"
        parent="#chat-app"
        :click-not-close="clickNotClose"
        :hidden-background="clickNotClose"
      >
        <!-- USER PROFILE SIDEBAR -->
        <user-profile
          :active="activeProfileSidebar"
          :user-id="userProfileId"
          :user-profile="profile"
          class="user-profile-container"
          @closeProfileSidebar="closeProfileSidebar"
        >
          User Profile
        </user-profile>

        <div class="chat__profile-search flex p-4">
          <div class="relative inline-flex">
            <vs-avatar
              class="m-0 border-2 border-solid border-white"
              :src="profile.imageUrl"
              size="40px"
              style="cursor: default !important"
            />
            <!-- <div
            class="h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0"
            :class="'bg-' + getStatusColor(true)"
          ></div> -->
          </div>
          <vs-input v-model="search" icon="search" class="w-full mx-5 input-rounded-full no-icon-border" placeholder="Search chats" />
        </div>

        <vs-divider class="d-theme-border-grey-light m-0" />
        <VuePerfectScrollbar class="chat-scroll-area pt-4 h-full" :settings="settings">
          <!-- ACTIVE CHATS LIST -->
          <div class="chat__chats-list mb-8">
            <h3 id="active-chats" class="text-primary mb-5 px-4 inline-block">Active Chats</h3>
            <h4 v-if="!sorted.length" class="mb-5 px-4"> No current chats</h4>
            <ul class="chat__active-chats bordered-items">
              <li v-for="(contact, index) in sorted" :key="index" class="cursor-pointer" @click="updateActiveChatUser(contact)">
                <chat-contact
                  v-if="getProfileStatus(contact.userId) === true"
                  :contact="contact"
                  :unseen-msg="contact.empUnread"
                  :is-active-chat-user="isActiveChatUser(contact.applicationsId)"
                ></chat-contact>
              </li>
            </ul>
          </div>

          <!-- CONTACTS LIST -->
          <div class="chat__chats-list">
            <h3 id="applicant-chats" class="text-primary mb-5 px-4 inline-block">Applicants</h3>
            <h4 v-if="!chatContacts.length" class="mb-5 px-4"> No active applicants</h4>
            <ul class="chat__active-chats bordered-items">
              <li
                v-for="contact in chatContacts"
                :key="contact.id"
                class="cursor-pointer"
                :class="{
                  'bg-grey-100 shadow-lg': selectedContact == contact,
                  'bg-grey-100 shadow-lg': checkthis(contact),
                }"
                @click="
                  updateActiveChatUser(chats[contact.applicationsId] !== undefined ? chats[contact.applicationsId] : contact),
                    (selectedContact = contact)
                "
              >
                <chat-contact
                  v-if="getProfileStatus(contact.userId) === true"
                  :contact="contact"
                  :is-active-chat-user="isActiveChatUser(contact)"
                ></chat-contact>
              </li>
            </ul>
          </div>
        </VuePerfectScrollbar>
      </vs-sidebar>
      <chat-side
        :click-not-close="clickNotClose"
        :active-chat-user="activeChatUser"
        :is-chat-pinned="isChatPinned"
        :user-data="userData"
        :selected-contact="selectedContact"
        :typed-message="typedMessage"
        :settings="settings"
        @close="closeChat"
        @update-active-chat-user="updateActiveChatUser"
      ></chat-side>
    </div>
    <ChatTour />
  </div>
</template>

<script>
/* eslint-disable vue/attribute-hyphenation */

import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import contacts from './contacts';
import ChatSide from './ChatSide.vue';
import ChatContact from './ChatContact.vue';
import UserProfile from './UserProfile.vue';
import ChatTour from '../../components/tours/ChatTour.vue';
// import firebase from 'firebase/compat/app';
export default {
  name: 'VxSidebar',
  components: {
    VuePerfectScrollbar,
    ChatContact,
    UserProfile,
    ChatSide,
    ChatTour,
  },
  props: {
    application: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isChatActive: false,
      users: [],
      active: true,
      isHidden: false,
      search: '',
      contacts: contacts,
      searchContact: '',
      activeProfileSidebar: false,
      activeChatUser: null,
      userProfileId: -1,
      typedMessage: '',
      isChatPinned: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7,
      },
      settingsTwo: {
        maxScrollbarLength: 100,
        wheelSpeed: 0.7,
      },
      clickNotClose: true,
      isChatSidebarActive: true,
      windowWidth: window.innerWidth,
      userData: {},
      selectedContact: null,
    };
  },
  computed: {
    profile() {
      return this.$store.getters['employerModule/getUser'];
    },
    chatLastMessaged() {
      return (userId) => this.$store.getters['chatModule/chatLastMessaged'](userId);
    },
    chatUnseenMessages() {
      return (userId) => {
        const unseenMsg = this.$store.getters['chatModule/chatUnseenMessages'](userId);
        if (unseenMsg) return unseenMsg;
      };
    },

    activeUserId() {
      return this.$store.state.AppActiveUser.id;
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.img;
    },
    activeUserStatus() {
      return this.$store.state.AppActiveUser.status;
    },
    getStatusColor() {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus(isActiveUser);

        if (userStatus == 'online') {
          return 'success';
        } else if (userStatus == 'do not disturb') {
          return 'danger';
        } else if (userStatus == 'away') {
          return 'warning';
        } else {
          return 'grey';
        }
      };
    },
    chats() {
      var data = this.$store.state['chatModule'].data;
      return this.filterFunc(data);
    },

    sorted() {
      return Object.values(this.chats).sort(function (a, b) {
        let timeX = a.updatedAt;
        let timeY = b.updatedAt;
        return timeY - timeX;
      });
    },
    chatContacts() {
      var data = this.$store.state['applicationsModule'].data;
      return this.filterFunc(data);
    },
    isActiveChatUser() {
      return (userId) => userId == this.activeChatUser;
    },
    employerChat() {
      return this.$store.getters['chatModule/chatDataOfUser'](this.activeChatUser);
    },
  },
  watch: {
    employerChat: {
      handler: function (val) {
        const activeChat = val;
        if (!activeChat.messages[activeChat.messages.length - 1].read && activeChat.empUnread > 0) {
          activeChat.messages[activeChat.messages.length - 1].read = true;
          this.$store.dispatch('chatModule/patch', { id: activeChat.id, messages: activeChat.messages, empUnread: 0 });
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.application) {
      this.updateActiveChatUser(this.application);
      this.selectedContact = this.application;
    }
  },
  created() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleWindowResize);
    });
    this.setSidebarWidth();
    this.isChatActive = false;

    // this.chatContacts.forEach((chat) => {
    //   let user = firebase.firestore().collection('users').doc(chat.userId).get();
    //   this.users.push(user.data());
    // });
    // console.log(this.users);
  },
  beforeDestroy: function () {
    this.isChatActive = false;
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    getProfileStatus(userId) {
      let user = this.$store.getters['userModule/getUser'][userId];
      if (user) {
        if (!user.profileDeactivated) {
          if (user.active === true) {
            return true;
          } else {
            return false;
          }
        }
        if (user.profileDeactivated) {
          if (user.profileDeactivated === true || user.active === false) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    closeChat() {
      this.isChatActive = false;
    },
    checkthis(contact) {
      return this.selectedContact?.id == contact?.id;
    },
    filterFunc(chatData) {
      var filtered = Object.keys(chatData)
        .filter((key) => {
          return (
            chatData[key].userData.userName.toLowerCase().includes(this.search.toLowerCase()) ||
            chatData[key].jobData.jobTitle.toLowerCase().includes(this.search.toLowerCase())
          );
        })
        .map((key) => {
          return chatData[key];
        });
      return filtered;
    },
    getUserStatus(isActiveUser) {
      return isActiveUser ? this.$store.state.AppActiveUser.status : this.contacts[this.activeChatUser].status;
    },
    closeProfileSidebar(value) {
      this.activeProfileSidebar = value;
    },
    updateUserProfileId(userId) {
      this.userProfileId = userId;
      this.activeProfileSidebar = !this.activeProfileSidebar;
    },
    updateActiveChatUser(contactId) {
      this.activeChatUser = contactId.applicationsId;
      this.selectedContact = contactId;
      let chatData = this.$store.getters['chatModule/chatDataOfUser'](this.activeChatUser);
      if (chatData && chatData.employerId !== chatData.lastSentBy && chatData.empUnread > 0) {
        const messages = chatData.messages.map((message) => {
          if (message.senderId !== chatData.employerId && !message.read) {
            return { ...message, read: true };
          }
          return message;
        });
        this.$store.dispatch('chatModule/patch', { id: chatData.id, messages, empUnread: 0 });
      }

      this.userData = chatData;
      if (window.innerWidth <= 577) {
        this.isChatActive = true;
      }

      if (chatData && chatData.userCount > 0) {
        chatData.userCount = 0;
        // this.$store.dispatch('chatModule/set', chatData);
      }
      if (chatData) this.isChatPinned = chatData.isPinned;
      else this.isChatPinned = true;
      this.toggleChatSidebar();
      this.typedMessage = '';
    },
    showProfileSidebar(userId) {
      this.userProfileId = userId;
      this.activeProfileSidebar = !this.activeProfileSidebar;
    },
    toggleIsChatPinned(value) {
      this.isChatPinned = value === undefined ? false : value;
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 767) {
        this.isChatSidebarActive = this.clickNotClose = false;
      } else {
        this.isChatSidebarActive = this.clickNotClose = true;
      }
    },
    toggleChatSidebar(value = false) {
      if (!value && this.clickNotClose) return;
      this.isChatSidebarActive = value;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuesax/apps/chat.scss';
.vs-con-textarea {
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 0px;
}

#chat-app .vs-sidebar--background {
  z-index: 0 !important;
}
</style>
